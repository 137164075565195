import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)
import graph from "./graph";
export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    graph
  },
  plugins: [
    createPersistedState({
      key: "bsVuex",
      storage: window.sessionStorage,
      reducer(val) {
        return {
          graph: val.graph
        }
      }
    })
  ]
})
