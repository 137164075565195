import {Loading} from "element-ui";

export function load_show (){
    const load = Loading.service({
        fullscreen: true,
        text:"正在解析中……",
        spinner:"el-icon-loading",
        background:"rgba(0, 0, 0, 0.8)"

    })

    return load;
}
