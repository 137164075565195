import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/stgx',
    name: 'stgx',
    component: () => import( '../views/graph_companyRelation/index.vue')
  },
  {
    path: '/',
    name: 'home',
    component: () => import( '../views/home/index')
  },
  {
    path: '/qa',
    name: 'qa',
    component: () => import( '../views/qa/index')
  },
  {
    path: '/relation',
    name: 'relation',
    component: () => import( '../views/relation')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
