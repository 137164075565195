export default {
    namespaced: true,
    state: {
        nodeTypeList: [
            // {
            //     name: "企业",
            //     size: 95,
            //     color: "#53B453",
            //     strokeColor: "#53B453",
            //     labelColor: "#fff",
            //     fontSize: 12,
            //     nodeType: 1,
            //     show: true
            // },
            {
                name: "采购人",
                size: 95,
                color: "#53B453",
                strokeColor: "#53B453",
                labelColor: "#fff",
                fontSize: 12,
                nodeType: 5,
                show: true
            },
            {
                name: "供应商",
                size: 95,
                color: "rgba(42, 184, 179, 1)",
                strokeColor: "rgba(42, 184, 179, 1)",
                labelColor: "#fff",
                fontSize: 12,
                nodeType:6,
                show: true
            },
            {
                name: "自然人",
                size: 60,
                color: "#FF8700",
                strokeColor: "#FF8700",
                labelColor: "#fff",
                fontSize: 12,
                nodeType: 2,
                show: true
            },
            {
                name: "物料",
                size: 60,
                color: "#1C90FF",
                strokeColor: "#1C90FF",
                labelColor: "#fff",
                fontSize: 12,
                nodeType: 4,
                show: true
            },
            {
                name: "项目",
                size: 95,
                color: "#CB2A8F",
                strokeColor: "#CB2A8F",
                labelColor: "#fff",
                fontSize: 12,
                nodeType: 3,
                show: true
            }],
        edgeTypeList: [
            {
              name:"中标",
              color: "rgba(184, 49, 49, 1)",
              edgeType: 2,
              show: true

            },
            {
                name: "参股任职",
                color: "#5B83CB",
                edgeType: 6,
                show: true
            },
            {
                name: "采购物料",
                color: "#81B75D",
                edgeType: 7,
                show: true
            },
            {
                name: "发布招标",
                color: "#81D3F8",
                edgeType: 9,
                show: true
            },
            {
                name: "参与投标",
                color: "rgba(228, 117, 19, 1)",
                edgeType: 10,
                show: true
            },
            {
                name: "代理",
                color: "rgba(248, 129, 242, 1)",
                edgeType: 15,
                show: true
            }
        ],
        queryHistory: [],
        stgxNodeTypeList: [
            {
                name: "采购单位",
                size: 85,
                color: "rgba(144, 238, 144, 1)",
                strokeColor: "rgba(144, 238, 144, 1)",
                labelColor: "#fff",
                fontSize: 12,
                nodeType: 1,
                show: true
            },
            {
                name: "招标机构",
                size: 85,
                color: "rgba(91, 131, 203, 1)",
                strokeColor: "rgba(91, 131, 203, 1)",
                labelColor: "#fff",
                fontSize: 12,
                nodeType: 2,
                show: true
            },
            {
                name: "投标人",
                size: 85,
                color: "rgb(222,183,65)",
                strokeColor: "rgb(222,183,65)",
                labelColor: "#fff",
                fontSize: 12,
                nodeType: 3,
                show: true
            },
            {
                name: "自然人",
                size: 50,
                color: "#ff4500",
                strokeColor: "#ff4500",
                labelColor: "#fff",
                fontSize: 12,
                nodeType: 4,
                show: true
            },
            {
                name: "产品",
                size: 70,
                color: "#ff8c00",
                strokeColor: "#ff8c00",
                labelColor: "#fff",
                fontSize: 12,
                nodeType: 5,
                show: true
            },
            {
                name: "项目",
                size: 115,
                color: "#00ced1",
                strokeColor: "#00ced1",
                labelColor: "#fff",
                fontSize: 12,
                nodeType: 6,
                show: true
            },
            {
                name: "股东",
                size: 60,
                color: "#1e90ff",
                strokeColor: "#1e90ff",
                labelColor: "#fff",
                fontSize: 12,
                nodeType: 7,
                show: true
            },
            // {
            //     name: "产品",
            //     size: 50,
            //     color: "#c71585",
            //     strokeColor: "#c71585",
            //     labelColor: "#fff",
            //     fontSize: 12,
            //     nodeType: 8,
            //     show: true
            // },
            {
                name: "制造商",
                size: 75,
                color: "#c7158577",
                strokeColor: "#c7158577",
                labelColor: "#fff",
                fontSize: 12,
                nodeType: 9,
                show: true
            },{
                name: "评标办法",
                size: 50,
                color: "#c71585",
                strokeColor: "#c71585",
                labelColor: "#fff",
                fontSize: 12,
                nodeType: 10,
                show: true
            },{
                name: "最高限价",
                size: 50,
                color: "rgba(255, 69, 0, 0.68)",
                strokeColor: "rgba(255, 69, 0, 0.68)",
                labelColor: "#fff",
                fontSize: 12,
                nodeType: 11,
                show: true
            },{
                name: "合同",
                size: 50,
                color: "rgba(238,155,126,0.68)",
                strokeColor: "rgba(255, 69, 0, 0.68)",
                labelColor: "#fff",
                fontSize: 12,
                nodeType: 12,
                show: true
            },{
                name: "报关相关",
                size: 75,
                color: "rgba(13,112,17,0.68)",
                strokeColor: "rgba(72,107,12,0.68)",
                labelColor: "#fff",
                fontSize: 12,
                nodeType: 13,
                show: true
            }
        ],
        stgxEdgeTypeList: [
            {
                name: "采购单位",
                color: "rgba(184, 49, 49, 1)",
                edgeType: 1,
                show: true
            },
            {
                name: "招标机构",
                color: "#5B83CB",
                edgeType: 2,
                show: true
            },
            {
                name: "投标人",
                color: "#81B75D",
                edgeType: 3,
                show: true
            },
            {
                name: "自然人",
                color: "#ffd700",
                edgeType: 4,
                show: true
            },
            {
                name: "产品",
                color: "#00ced1",
                edgeType: 5,
                show: true
            },
            // {
            //     name: "项目采购产品",
            //     color: "#c71585",
            //     edgeType: 6,
            //     show: true
            // },
            {
                name: "中标人",
                color: "#c7158577",
                edgeType: 7,
                show: true
            },
            {
                name: "参股任职",
                color: "#ff8c00",
                edgeType: 8,
                show: true
            },
            {
                name: "制造商",
                color: "#0b730b",
                edgeType: 9,
                show: true
            },
            {
                name: "评标办法",
                color: "#c71585",
                edgeType: 10,
                show: true
            },
            {
                name: "最高限价",
                color: "rgb(255, 120, 0)",
                edgeType: 11,
                show: true
            },
            {
                name: "采购合同",
                color: "rgb(255, 120, 0)",
                edgeType: 12,
                show: true
            },
            {
                name: "报关相关",
                color: "rgb(255, 120, 0)",
                edgeType: 13,
                show: true
            }
            ],
        stgxApinums:3,
        stgxQueryTarget:1
    },
    mutations: {
        setNodeTypeList(state, nodeTypeList) {
            state.nodeTypeList = nodeTypeList;
        },
        setEdgeTypeList(state, edgeTypeList) {
            state.edgeTypeList = edgeTypeList;
        },
        setStgxNodeTypeList(state,stgxNodeTypeList){
            state.stgxNodeTypeList = stgxNodeTypeList;
        },
        setStgxEdgeTypeList(state,stgxEdgeTypeList){
            state.stgxEdgeTypeList = stgxEdgeTypeList;
        },
        setQueryHistory(state, queryHistory) {
            state.queryHistory = queryHistory;
        },
        clearQueryHistory(state){
            state.queryHistory =[];
        },
        setApiNums(state,apiNums){
            state.stgxApinums = apiNums;
        },
        setQueryTarget(state,target){
            state.stgxQueryTarget = target;
        }
    },
}
