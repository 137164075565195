import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import _ from "underscore";
import axios from 'axios'
import G6 from '@antv/g6';
Vue.config.productionTip = false
import * as Util from '@/util/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "@/assets/style/index.scss";
Vue.use(ElementUI,{size:'mini'});

Vue.prototype.$_ = _;
Vue.prototype.$axios = axios;
Vue.prototype.$G6 = G6;
Vue.prototype.$Util = Util;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
